import React, { useEffect, useState } from "react";
import Header from "../Layouts/TeamLayouts/Header";
import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, IconButton, Drawer , Grid, Select, MenuItem} from "@mui/material";
import Card from "../components/common/Card";
import EmptyData from "../components/common/EmptyData";
import NewInvoice from "./components/Invoice/NewInvoice";
import cancelIcon from "../assets/icons/cancel.svg";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";

import { getInvoices, getTeamInvoice } from "../api";
import { FaAngleRight } from "react-icons/fa6";
import Loader from "../components/common/Loader";
import PaymentStatus from "./components/Invoice/PaymentStatus";
import Pusher from 'pusher-js';
import SEO from "../components/SEO";

const TeamInvoice = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false)
      const [paymentStatus, setPaymentStatus] = useState('');
    const [open, setOpen] = React.useState(false);
    const [invoice_id, setInvoiceId] = useState(null)
    const [status, setStatus] = useState(null)
  const toggleDrawer = (newOpen, id, status) => () => {
    setOpen(newOpen);
setInvoiceId(id)
setStatus(status)
  };

  const handleGetInvoice = async () =>{
    setIsLoading(true)
    await getTeamInvoice()
    .then((res)=>{

      setData(res?.data?.data)
      setIsLoading(false)
    }).catch((err)=>{
            setIsLoading(false)

    })
  }

useEffect(()=>{
  handleGetInvoice()
},[])

  useEffect(() => {
    // Configure Pusher client
    const pusher = new Pusher('a2814cd83306f1189e9a', {
      cluster: 'mt1',
    });

    // Subscribe to the 'payments' channel
    const channel = pusher.subscribe(`vendor-${2}`);

    // Bind to the 'update' event
    channel.bind('paid_invoice', function (eventData) {

      const invoices = updateInvoice(data, eventData?.invoice_id, eventData?.status)

      setData(invoices);
    });

    // Clean up the subscription
    return () => {
      channel.unbind_all();
      channel.unsubscribe();
    };
  }, [status]);





const updateInvoice = (invoices,id, status ) =>{

return  invoices?.map((invoice) =>{
if(   invoice?.invoice_id === id){
  invoice.status = status
  return invoice
}
return invoice
} )

}


  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePageLeft = (event, newPage) => {
    setPage(page - 1);
  };
  const handleChangePageRight = (event, newPage) => {
    setPage(page + 1);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const startRow = page * rowsPerPage + 1;
  const endRow = Math.min(startRow + rowsPerPage - 1, data?.length);
  const rowsText = `${startRow}-${endRow} of ${data?.length}`;
  return (
    <>

            <SEO
        title="VibezsUp Vendor App |Team-Invoice"
        description="Leverage our Supercharged Vendor App for Managing Genrated Leads, Reservation, Menu & Profile Information"
        name="Vibezsup"
        type="article"
      />
    {
      isLoading && <Loader/>
    }
    {/* <Comingsoon/> */}
      <Header
        title={"Generate Invoice"}
        caption={"Easily collect payment from walk-in VibezsUp users"}
      />

      <Box sx={{ mt: 15,  }}>
        <Card height="">
          {!data || data?.length === 0 ? (
            <>
            <Box sx={{pt:10}}>
              <EmptyData
                text={
                  "You are yet to generate your first invoice. Use the button below to collect instant payment from walk-in VibezsUp users."
                }
                width={"50%"}
              />
              <Box align="center" sx={{ mt: 5 }}>
      <NewInvoice action={handleGetInvoice}/>
              </Box>
</Box>

            </>
          ) : (
            <>
            <Box sx={{px:3}}>
              <Box sx={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>

              <Typography sx={{color:'#151515', fontWeight:600}}>All Invoice</Typography>
              <NewInvoice action={handleGetInvoice} variant="outlined"/>
              </Box>
              <Box sx={{margin:'0 auto', width:'90%',  mt:3, }}>
<TableContainer>
  <Table>
    <TableHead>
      <TableRow>
        <TableCell sx={{borderBottom:'none',  color:'#5b5b5b'}}>Paying Guest</TableCell>
        <TableCell sx={{borderBottom:'none',  color:'#5b5b5b'}}>Inv Value</TableCell>
        <TableCell sx={{borderBottom:'none',  color:'#5b5b5b'}}>Time</TableCell>
        <TableCell sx={{borderBottom:'none',  color:'#5b5b5b'}}>Waiter</TableCell>
        <TableCell sx={{borderBottom:'none',  color:'#5b5b5b'}}>Status</TableCell>
        <TableCell align="right" sx={{borderBottom:'none',  color:'#5b5b5b'}}></TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {
        data?.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )?.map((item, index) => (
          <TableRow>
            <TableCell sx={{borderBottom:'none'}}>{item?.paying_guest
}</TableCell>
            <TableCell sx={{borderBottom:'none'}}>N{parseFloat(item?.inv_value).toLocaleString()
}</TableCell>
            <TableCell sx={{borderBottom:'none'}}>{formatDateTime(item?.
time
)}</TableCell>
            <TableCell sx={{borderBottom:'none'}}>{item?.
waiter
}</TableCell>
            <TableCell sx={{borderBottom:'none'}}>

              {
                item?.status === "pending" && (
                  <Typography sx={{color:'#C56000', fontSize:'12px',  fontWeight:500, px:1, border:'1px  solid #C56000', textAlign:'center', borderRadius:3,  bgcolor:'#FFF2E5'}}>Pending</Typography>
                )
              }
              {
                item?.status === "failed" && (
                  <Typography sx={{color:'#A71200', fontSize:'12px',  fontWeight:500, px:1, border:'1px  solid #A71200', textAlign:'center', borderRadius:3,  bgcolor:'#FFE8E5'}}>Failed</Typography>
                )
              }
              {
                item?.status === "declined" && (
                  <Typography sx={{color:'#A71200', fontSize:'12px',  fontWeight:500, px:1, border:'1px  solid #A71200', textAlign:'center', borderRadius:3,  bgcolor:'#FFE8E5'}}>Declined</Typography>
                )
              }
              {
                item?.status === "paid" && (
                  <Typography sx={{color:'#007E23', fontSize:'12px',  fontWeight:500, px:1, border:'1px  solid #007E23', textAlign:'center', borderRadius:3,  bgcolor:'#E8FFE8'}}>Paid</Typography>
                )
              }
            </TableCell>
            <TableCell  align="right" sx={{borderBottom:'none'}}>
              <IconButton          onClick={toggleDrawer(true, item?.invoice_id, item?.status)}>
                <FaAngleRight style={{color:'#75007E', fontSize:'15px'}} />
              </IconButton>
            </TableCell>
          </TableRow>
        ))
      }
    </TableBody>
  </Table>
</TableContainer>
              </Box>
            </Box>
            
            </>
          )}

                  <Grid container sx={{ bgcolor: "#fff", mt: 3, px: 4, py: 1 }}>
        <Grid
          item
          lg={4}
          md={4}
          xs={4}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Typography
            sx={{
              color: "#424242",
              fontSize: "13px",
              fontFamily: "outfit",
            }}
          >
            {rowsText}
          </Typography>
        </Grid>
        <Grid item lg={8} md={8} xs={8}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: 2,
            }}
          >
            <Typography
              sx={{
                color: "#424242",
                fontSize: "13px",
                fontFamily: "outfit",
              
              }}
            >
              You are currently on Page
            </Typography>
            <Select
              size="small"
              sx={{
                fontFamily: "outfit",
                color: "#424242",
                fontSize: "13px",
              }}
              value={rowsPerPage}
              onChange={handleChangeRowsPerPage}
              InputProps={{
                style: {
                  fontFamily: "outfit",
                  fontSize: "13px",
                  // borderRadius: "8px",

                  // Replace with your desired font family
                },
              }}
            >
              <MenuItem
                sx={{ fontFamily: "outfit", color: "#424242" }}
                value={5}
              >
                5
              </MenuItem>
              <MenuItem
                sx={{ fontFamily: "outfit", color: "#424242" }}
                value={10}
              >
                10
              </MenuItem>
              <MenuItem
                sx={{ fontFamily: "outfit", color: "#424242" }}
                value={25}
              >
                25
              </MenuItem>
              <MenuItem
                sx={{ fontFamily: "outfit", color: "#424242" }}
                value={50}
              >
                50
              </MenuItem>
            </Select>
            <Box
              sx={{
                borderLeft: "1px solid #424242",
                pl: { md: 4, xs: 2 },
                ml: { md: 3, xs: 2 },
              }}
            >
              <IconButton
                disabled={startRow === 1}
                onClick={handleChangePageLeft}
                sx={{
                  width: "30px",
                  height: "30px",
                  border: "1px solid #424242",
                  "&.Mui-disabled": {
                    border: "1px solid #4242421a",
                  },
                }}
              >
                <ChevronLeftRoundedIcon />
              </IconButton>
              <IconButton
                disabled={endRow === data?.length}
                onClick={handleChangePageRight}
                sx={{
                  ml: 4,
                  width: "30px",
                  height: "30px",
                  border: "1px solid #424242",
                  "&.Mui-disabled": {
                    border: "1px solid #4242421a",
                  },
                }}
              >
                <ChevronRightRoundedIcon />
              </IconButton>
            </Box>
          </Box>
        </Grid>
      </Grid>
        </Card>
      </Box>


      <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
        <Box
          className="hide_scrollbar"
          sx={{
            bgcolor: "#fff",
            height: "100vh",
            width: 650,
            p: 5,
            boxSizing: "border-box",
            overflow: "scroll",
            transition: "0.2s all linear",
          }}
        >
     <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{ fontWeight: 700 }}
              variant="subtitle1"
              color="primary"
            >
          {invoice_id}
            </Typography>
            <IconButton onClick={toggleDrawer(false)}>
              <img src={cancelIcon} />
            </IconButton>
          </Box>
<PaymentStatus id={invoice_id} idShow={false} status={status}/>
        </Box>
      </Drawer>
    </>
  );
};

export default TeamInvoice;


function formatDateTime(dateTimeString) {
  // Parse the given datetime string into a Date object
  const dateTime = new Date(dateTimeString);

  // Format the date
  const month = dateTime.toLocaleString('default', { month: 'short' });
  const day = dateTime.getDate();
  const hours = dateTime.getHours() % 12 || 12; // Convert hours to 12-hour format
  const minutes = dateTime.getMinutes();
  const ampm = dateTime.getHours() >= 12 ? 'pm' : 'am';

  // Format the datetime string in the desired format
  const formattedDateTime = `${month} ${day} - ${hours}:${minutes < 10 ? '0' : ''}${minutes}${ampm}`;

  return formattedDateTime;
}


