import {
  Box,
  Button,
  Drawer,
  Typography,
  IconButton,
  Table,
  TableBody,
  TableRow,
  TableCell,
  ListItem,
  Avatar,
  TextField,
  InputAdornment,
  LinearProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import cancelIcon from "../../../assets/icons/cancel.svg";

import plus from "../../../assets/icons/add.svg";
import minus from "../../../assets/icons/dash.svg";
import { getProflie, getTeamMenu } from "../../../api";

import Payer from "./Payer";

const NewInvoice = ({ variant, action }) => {
  const [open, setOpen] = React.useState(false);
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
    setStep(1)
  };

  const [isLoading, setIsLoading] = useState(null);
  const [menuData, setMenuData] = useState(null);
  const [filter, setFilter] = useState(null);
  const [label, setLabel] = useState(null);
  const [step, setStep] = useState(1);
  const [selectedItem, setSelectedItem] = useState([]);

  const [data, setData] = useState(null);





  const handleGetMenu = async () => {
    setIsLoading(true);

    await getTeamMenu()
      .then((res) => {
        setIsLoading(false);

        setMenuData(res?.data?.data);
        setLabel(res?.data?.data);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const handleBack = () => {
    setStep(1);
  };

  const handleFilter = () => {
    const filteredData = label?.filter((obj) => obj?.name === filter);
    setMenuData(filteredData);
  };

  useEffect(() => {
    handleFilter();
  }, [filter]);

  useEffect(() => {
    handleGetMenu();
  }, []);

  const [selectedMenuList, setSelectedMenuList] = useState([]);
  const [quantity, setQuantity] = useState({});

  const addToCart = (menuId, menuItem) => {
    const itemIndex = selectedMenuList.findIndex(
      (item) => item.menu_id === menuId
    );

    if (itemIndex === -1) {
      setSelectedMenuList([
        ...selectedMenuList,
        { menu_id: menuId, quantity: 1 },
      ]);
      setQuantity((prevQuantity) => ({
        ...prevQuantity,
        [menuId]: 1,
      }));
    } else {
      const updatedMenuList = [...selectedMenuList];
      updatedMenuList[itemIndex].quantity++;
      if (updatedMenuList[itemIndex].quantity < 1) {
        updatedMenuList[itemIndex].quantity = 1; // Ensure minimum quantity is 1
      }
      setSelectedMenuList(updatedMenuList);
      setQuantity((prevQuantity) => ({
        ...prevQuantity,
        [menuId]: (prevQuantity[menuId] || 0) + 1,
      }));
    }
    setSelectedItem([
      ...selectedItem,
      { ...menuItem, menu_id: menuId, quantity: quantity[menuId] || 1 },
    ]);
  };
  const increaseQuantity = (menuId) => {
    setQuantity((prevQuantity) => ({
      ...prevQuantity,
      [menuId]: (prevQuantity[menuId] || 0) + 1,
    }));

    setSelectedMenuList((prevList) => {
      const updatedList = prevList.map((item) => {
        if (item.menu_id === menuId) {
          return { ...item, quantity: (item.quantity || 0) + 1 };
        }
        return item;
      });
      return updatedList;
    });

    setSelectedItem((prevItems) => {
      const updatedItems = prevItems.map((item) => {
        if (item.menu_id === menuId) {
          return { ...item, quantity: (item.quantity || 0) + 1 };
        }
        return item;
      });
      return updatedItems;
    });
  };



  // Function to handle decreasing quantity
  const decreaseQuantity = (menuId) => {
    if (quantity[menuId] <= 1) {
      removeItem(menuId);
    }

    if (quantity[menuId] > 1) {
      setQuantity((prevQuantity) => ({
        ...prevQuantity,
        [menuId]: prevQuantity[menuId] - 1,
      }));
    }

    setSelectedMenuList((prevList) => {
      const updatedList = prevList.map((item) => {
        if (item.menu_id === menuId) {
          return { ...item, quantity: (item.quantity || 0) - 1 };
        }
        return item;
      });
      return updatedList;
    });

    // Update selectedNewItems state
    setSelectedItem((prevItems) => {
      const updatedItems = prevItems.map((item) => {
        if (item.menu_id === menuId) {
          return { ...item, quantity: (item.quantity || 0) - 1 };
        }
        return item;
      });
      return updatedItems;
    });
  };

  const removeItem = (menuId) => {
    // Remove item from selectedMenuList
    const updatedMenuList = selectedMenuList.filter(
      (item) => item.menu_id !== menuId
    );
    setSelectedMenuList(updatedMenuList);

    // Remove item from selectedNewItems
    const updatedNewItems = selectedItem.filter(
      (item) => item.menu_id !== menuId
    );
    setSelectedItem(updatedNewItems);
    setQuantity((prevQuantity) => ({
      ...prevQuantity,
      [menuId]: 0,
    }));

    setSelectedMenuList((prevList) => {
      const updatedList = prevList.map((item) => {
        if (item.menu_id === menuId) {
          return { ...item, quantity: 0 };
        }
        return item;
      });
      return updatedList;
    });
  };

  const calculateTotalUnitPrice = (data) => {
    let totalPrice = 0;
    data.forEach((item) => {
      totalPrice += item.quantity * item.unit_price;
    });
    return totalPrice;
  };

  return (
    <>
      {variant === "outlined" ? (
        <Button
          variant="outlined"
          sx={{
            px: 4,
            borderRadius: "7px",
            bgcolor: "#FCEDFE",
            border: "1px solid #75007E",
          }}
          onClick={toggleDrawer(true)}
        >
          Generate Invoice
        </Button>
      ) : (
        <Button
          variant="contained"
          sx={{ px: 6, borderRadius: "7px" }}
          onClick={toggleDrawer(true)}
        >
          Generate Invoice
        </Button>
      )}

      <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
        <Box
          className="hide_scrollbar"
          sx={{
            bgcolor: "#fff",
            height: "100vh",
            width: 650,
            p: 5,
            boxSizing: "border-box",
            overflow: "scroll",
            transition: "0.2s all linear",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{ fontWeight: 700 }}
              variant="subtitle1"
              color="primary"
            >
              New Invoice
            </Typography>
            <IconButton onClick={toggleDrawer(false)}>
              <img src={cancelIcon} />
            </IconButton>
          </Box>

          {step === 1 ? (
            <>
              <Box
                sx={{ mt: 5, bgcolor: "#FCEDFE", p: 3, borderRadius: "12px" }}
              >
                <Typography color={"primary"} variant="subtitle1">
                  Add Menu Item to Cart
                </Typography>
                <Box
                  className="hide_scrollbar"
                  sx={{
                    mt: 3,
                    display: "flex",
                    columnGap: 2,
                    overflow: "scroll",
                    transition: "0.2s all linear",
                  }}
                >
                  <Box
                    onClick={() => setMenuData(label)}
                    sx={{
                      height: "28px",
                      border: "1px solid #8f8f8f",
                      width: "fit-content",
                      px: 3,
                      display: "grid",
                      placeItems: "center",
                      fontSize: "12px",
                      borderRadius: "8px",
                      cursor: "pointer",
                      fontWeight: 500,
                      transition: "0.2s all linear",
                      color: "#000000b7",
                      "&:hover": {
                        border: "1px solid #75007E",
                        color: "#75007E",
                      },
                    }}
                  >
                    All
                  </Box>
                  {label?.map((item, index) => (
                    <Box
                      key={index}
                      onClick={() => setFilter(item?.name)}
                      sx={{
                        height: "28px",
                        border: "1px solid #8f8f8f",
                        width: "fit-content",
                        px: 3,
                        display: "grid",
                        placeItems: "center",
                        fontSize: "12px",
                        borderRadius: "8px",
                        cursor: "pointer",
                        fontWeight: 500,
                        transition: "0.2s all linear",
                        color: "#000000b7",
                        "&:hover": {
                          border: "1px solid #75007E",
                          color: "#75007E",
                        },
                        ...(filter === item?.name && {
                          border: "1px solid #75007E",
                          color: "#75007E",
                        }),
                      }}
                    >
                      {item?.name}
                    </Box>
                  ))}
                </Box>

                {menuData?.map((item, index) => {
                  return (
                    <Box sx={{ mt: 4 }} key={index}>
                      <Typography variant="subtitle2" color="primary">
                        {item?.name} - {item?.menu_count} Menu Items
                      </Typography>
                      <Table sx={{ mt: 2 }}>
                        <TableBody>
                          {!item?.
menu_Item
 || item?.items?.length === 0 ? (
                            <>
                              <Typography
                                sx={{
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "#151515",
                                }}
                              >
                                No Menu Item Available
                              </Typography>
                            </>
                          ) : (
                            <>
                              {item?.
menu_Item
?.map((item, index) => {
                                const isDelisted = item?.delisted === true;
                                return (
                                  <TableRow
                                    key={index}
                                    sx={{ opacity: isDelisted && 0.2 }}
                                  >
                                    <TableCell
                                      align="center"
                                      sx={{ border: "none" }}
                                    >
                                      <Avatar
                                        src={item?.image}
                                        variant="rounded"
                                        sx={{ height: "53px", width: "56px" }}
                                      />
                                    </TableCell>
                                    <TableCell sx={{ border: "none" }}>
                                      <Typography
                                        sx={{
                                          color: "#151515",
                                          fontWeight: 500,
                                          fontSize: "12px",
                                        }}
                                      >
                                        {item?.name}
                                      </Typography>
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        border: "none",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                      }}
                                    >
                                      N
                                      {parseFloat(
                                        item?.unit_price
                                      ).toLocaleString()}
                                    </TableCell>
                                    <TableCell
                                      align="right"
                                      sx={{
                                        border: "none",
                                        cursor: "pointer",
                                        display: isDelisted && "none",
                                      }}
                                    >
                                      {quantity[item.id] > 0 ? (
                                        <>
                                          <Box align="right">
                                            <Box
                                              sx={{
                                                border: "1px solid #75007E",
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                width: "89px",
                                                borderRadius: "4px",
                                                height: "31px",
                                              }}
                                            >
                                              <IconButton
                                                onClick={() =>
                                                  decreaseQuantity(item.id)
                                                }
                                                sx={{
                                                  borderRadius: 2,
                                                  color: "#75007E",
                                                  fontWeight: 500,
                                                  py: 1,
                                                  cursor:
                                                    quantity === 1 &&
                                                    "not-allowed",
                                                }}
                                              >
                                                <img src={minus} />
                                              </IconButton>
                                              <Typography
                                                sx={{
                                                  fontSize: "12px",
                                                  fontWeight: 700,
                                                  color: "#75007E",
                                                }}
                                              >
                                                {quantity[item.id]}
                                              </Typography>
                                              <IconButton
                                                onClick={() =>
                                                  increaseQuantity(item.id)
                                                }
                                                sx={{
                                                  borderRadius: 2,
                                                  fontWeight: 500,
                                                  color: "#75007E",
                                                  py: 1,
                                                  fontSize: "14px",
                                                }}
                                              >
                                                <img src={plus} />
                                              </IconButton>
                                            </Box>
                                          </Box>
                                        </>
                                      ) : (
                                        <>
                                          <Button
                                            onClick={() =>
                                              addToCart(item.id, item)
                                            }
                                          >
                                            <Typography
                                              sx={{
                                                color: "#75007E",
                                                textDecoration: "underline",
                                                fontWeight: 700,
                                                fontSize: "12px",
                                              }}
                                            >
                                              Add to cart
                                            </Typography>
                                          </Button>
                                        </>
                                      )}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </>
                          )}
                        </TableBody>
                      </Table>
                    </Box>
                  );
                })}
              </Box>

              <Box
                sx={{
                  mt: 4,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  <Typography
                    sx={{ color: "#A71200", fontWeight: 400, fontSize: "12px" }}
                  >
                    Bill Value
                  </Typography>
                  <Typography
                    sx={{ color: "#A71200", fontWeight: 700, fontSize: "14px" }}
                  >
                    N{calculateTotalUnitPrice(selectedItem).toLocaleString()}
                  </Typography>
                </Box>
                <Button
                  disabled={selectedMenuList?.length === 0}
                  onClick={() => setStep(2)}
                  variant="contained"
                >
                  Proceed to Checkout
                </Button>
              </Box>
            </>
          ) : (
            <>
              <Payer
                handleBack={handleBack}
                menuItems={selectedItem}
                data={menuData[0]}
                menu={selectedMenuList}
                action={action}
                closeDrawer={toggleDrawer}
              />
            </>
          )}
        </Box>
      </Drawer>
    </>
  );
};

export default NewInvoice;
