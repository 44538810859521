import React, { useState, useEffect } from "react";
import tagIcon from "../../../assets/icons/@.svg";
import backIcon from "../../../assets/icons/back.svg";
import { generateInvoice, getMenuList, searchPayerTeam, walkInReservationTeam } from "../../../api";
import {
  Box,
  Button,
  Drawer,
  Typography,
  IconButton,
  Avatar,
  TextField,
  InputAdornment,
  LinearProgress,
} from "@mui/material";
import Loader from "../../../components/common/Loader";
import { useSnackbar } from "notistack";
import PaymentStatus from "./PaymentStatus";

const Payer = ({ handleBack, menuItems, data, menu, action, closeDrawer }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [activeStep, setActiveStep] = useState(1);
  const [payerParams, setPayerParams] = useState("");
  const [searching, setSearching] = useState(false);
  const [payerList, setPayerList] = useState(null);

  const handleAlert = (message, variant) => {
    enqueueSnackbar(message, { variant });
  };

  const [user, setUser] = useState(null);

  const [paymentGenerated, setPaymentGenerated] = useState(false);

  const handleGetPayer = async () => {
    setSearching(true);
    await searchPayerTeam(payerParams)
      .then((res) => {
        setSearching(false);
        setPayerList(res?.data?.data);
      })
      .catch((err) => {
        setSearching(false);
      });
  };

  useEffect(() => {
    if (payerParams) {
      handleGetPayer();
    }
  }, [payerParams]);

  const handleSelectUser = (id) => {
    setActiveStep(2);
    setUser(id);
  };

  const calculateTotalUnitPrice = (data) => {
    let totalPrice = 0;
    data.forEach((item) => {
      totalPrice += item.quantity * item.unit_price;
    });
    return totalPrice;
  };

  const { consumption_tax, value_added_tax } = data?.tax;

  const [added_tax, setAdded_tax] = useState(0);
  const [consume_tax, setConsume_tax] = useState(0);

  useEffect(() => {
    if (consumption_tax === 1) {
      const consume_tax = (5 / 100) * calculateTotalUnitPrice(menuItems);
      setConsume_tax(consume_tax);
    } else {
      setConsume_tax(0);
    }

    if (value_added_tax === 1) {
      const added_tax = (7.5 / 100) * calculateTotalUnitPrice(menuItems);
      setAdded_tax(added_tax);
    } else {
      setAdded_tax(0);
    }
  }, [data, menuItems]);

  const [isLoading, setIsLoading] = useState(false);
  const [invoiceId, setInvoiceId] = useState(null);

  const handleGenerateInvoice = async () => {

    setIsLoading(true);
    await walkInReservationTeam(menu, user?.id)
      .then((res) => {
        setIsLoading(false);
        action();
        setPaymentGenerated(true);
        setInvoiceId(res?.data?.data);
    
      })
      .catch((err) => {
        setIsLoading(false);
        handleAlert(`${err.response.data?.message}`, "error");

      });
  };
  return (
    <>
      {isLoading && <Loader />}
      {paymentGenerated ? (
        <>
          <PaymentStatus id={invoiceId} idShow={true} />
        </>
      ) : (
        <>
          {activeStep === 1 ? (
            <>
              <Box
                sx={{ mt: 5, bgcolor: "#FCEDFE", p: 3, borderRadius: "12px" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    columnGap: 2,
                    alignItems: "flex-start",
                  }}
                >
                  <IconButton onClick={handleBack}>
                    <img src={backIcon} />
                  </IconButton>
                  <Box>
                    <Typography
                      color="primary"
                      sx={{ fontWeight: 700, fontSize: "14px" }}
                    >
                      Payer Details
                    </Typography>
                    <Typography
                      color="primary"
                      sx={{
                        fontWeight: 400,
                        fontSize: "12px",
                        mt: 1,
                        width: "80%",
                      }}
                    >
                      Request the VibezsUp username of your guest so you can
                      request payment.
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ margin: "0 auto", width: "80%", mt: 5 }}>
                  <TextField
                    placeholder="Enter username of your guest"
                    value={payerParams}
                    onChange={(e) => setPayerParams(e.target.value)}
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start" sx={{ mr: 2 }}>
                          <img src={tagIcon} />
                        </InputAdornment>
                      ),
                      style: {
                        borderRadius: "8px",
                        border: "2px  solid #75007E",
                        fontSize: "13px",
                        fontWeight: 500,
                      },
                    }}
                  />
                  <Box sx={{ mt: 3 }}>
                    {searching && <LinearProgress />}
                    {(payerParams && !payerList) || payerList?.length === 0 ? (
                      <>
                        <Typography
                          sx={{
                            fontSize: "13px",
                            fontWeight: 700,
                            color: "#151515",
                          }}
                        >
                          Search Results
                        </Typography>

                        <Box
                          sx={{
                            mt: 3,
                            height: "100px",
                            display: "grid",
                            placeItems: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#5b5b5b",
                              textAlign: "center",
                              fontSize: "13px",
                              fontWeight: 500,
                            }}
                          >
                            {" "}
                            No user Found for "{payerParams}"
                          </Typography>
                        </Box>
                      </>
                    ) : (
                      <>
                        <Typography
                          sx={{
                            fontSize: "13px",
                            fontWeight: 700,
                            color: "#151515",
                          }}
                        >
                          Search Results
                        </Typography>

                        <Box
                          className="hide_scrollbar"
                          sx={{ mt: 4, maxHeight: "350px", overflow: "scroll" }}
                        >
                          {payerList?.map((item, index) => (
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                mb: 3,
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  columnGap: 2,
                                }}
                              >
                                <Avatar
                                  variant="rounded"
                                  src={item?.avatar}
                                  sx={{
                                    width: "40px",
                                    height: "40px",
                                    border: "1px solid #75007E",
                                    borderRadius: "50%",
                                  }}
                                />
                                <Box>
                                  <Typography
                                    sx={{
                                      fontWeight: 400,
                                      color: "#151515",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {item?.name}
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "12px",
                                      fontWeight: 300,
                                      color: "#5b5b5b",
                                    }}
                                  >
                                    @{item?.user_name}
                                  </Typography>
                                </Box>
                              </Box>
                              <Button
                                onClick={() => handleSelectUser(item)}
                                variant="outlined"
                                sx={{
                                  fontSize: "10px",
                                  fontWeight: 700,
                                  border: "1px  solid #75007e",
                                }}
                              >
                                Select User
                              </Button>
                            </Box>
                          ))}
                        </Box>
                      </>
                    )}
                  </Box>
                </Box>
              </Box>
            </>
          ) : (
            <>
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    columnGap: 2,
                    alignItems: "center",
                  }}
                >
                  <IconButton onClick={() => setActiveStep(1)}>
                    <img src={backIcon} />
                  </IconButton>

                  <Typography
                    color="primary"
                    sx={{ fontWeight: 700, fontSize: "14px" }}
                  >
                    Invoice Details Confirmation
                  </Typography>
                </Box>

                <Box
                  sx={{
                    mt: 3,
                    bgcolor: "#FCEDFE",
                    borderRadius: "12px",
                    boxSizing: "border-box",
                    p: 3,
                  }}
                >
                  <Typography
                    color="primary"
                    sx={{ fontWeight: 700, fontSize: "12px" }}
                  >
                    Paying Guest
                  </Typography>
                  <Box
                    sx={{
                      mt: 3,
                      bgcolor: "#151515",
                      height: "86px",
                      width: "65%",
                      borderRadius: "10px",
                      display: "flex",
                      justifyContent: "space-between",
                      px: 3,
                      alignItems: "center",
                      boxSizing: "border-box",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: 2,
                      }}
                    >
                      <Avatar
                        variant="circular"
                        src={user?.avatar}
                        sx={{
                          width: "50px",
                          height: "50px",
                          border: "1px solid #75007E",
                        }}
                      />
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: 400,
                            color: "#fff",
                            fontSize: "12px",
                          }}
                        >
                          {user?.name}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "12px",
                            fontWeight: 300,
                            color: "#fff",
                          }}
                        >
                          @{user?.user_name}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    mt: 3,
                    bgcolor: "#FCEDFE",
                    borderRadius: "12px",
                    boxSizing: "border-box",
                    p: 3,
                  }}
                >
                  <Typography
                    color="primary"
                    sx={{ fontWeight: 500, fontSize: "12px" }}
                  >
                    Reservation Order
                  </Typography>
                  <Box sx={{ mt: 2, borderBottom: "1px solid #75007E", pb: 3 }}>
                    {menuItems?.map((item, index) => (
                      <Box
                        sx={{
                          mb: 2,
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#151515",
                            fontWeight: 500,
                            fontSize: "13px",
                          }}
                        >
                          {item?.name} - {item?.quantity} Packs
                        </Typography>
                        <Typography
                          sx={{
                            color: "#151515 ",
                            fontWeight: 500,
                            fontSize: "13px",
                          }}
                        >
                          N
                          {parseFloat(
                            item?.unit_price * item?.quantity
                          ).toLocaleString()}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                  <Box sx={{ mt: 2, borderBottom: "1px solid #75007E", pb: 3 }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 700,
                          color: "#151515",
                          fontSize: "13px",
                        }}
                      >
                        Total
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: 700,
                          color: "#151515",
                          fontSize: "13px",
                        }}
                      >
                        N{calculateTotalUnitPrice(menuItems)?.toLocaleString()}
                      </Typography>
                    </Box>
                    {value_added_tax === 1 && (
                      <Box
                        sx={{
                          py: 1,
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 700,
                            fontSize: "13px",
                            color: "#151515",
                          }}
                        >
                          VAT - 7.5%
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: 700,
                            fontSize: "13px",
                            color: "#151515",
                          }}
                        >
                          N{parseFloat(added_tax.toFixed(2)).toLocaleString()}
                        </Typography>
                      </Box>
                    )}

                    {consumption_tax === 1 && (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontSize: "13px",
                            color: "#151515",
                          }}
                        >
                          Consumption Tax - 5%
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: 700,
                            fontSize: "13px",
                            color: "#151515",
                          }}
                        >
                          N{parseFloat(consume_tax.toFixed(2)).toLocaleString()}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mt: 2,
                    }}
                  >
                    <Typography sx={{ fontWeight: 700, color: "#007E23" }}>
                      Total Chargeable
                    </Typography>
                    <Typography sx={{ fontWeight: 700, color: "#007E23" }}>
                      N
                      {(
                        calculateTotalUnitPrice(menuItems) +
                        1000 +
                        added_tax +
                        consume_tax
                      ).toLocaleString()}
                    </Typography>
                  </Box>
                </Box>

                <Box sx={{ display: "grid", placeItems: "center", mt: 4 }}>
                  <Button
                    onClick={handleGenerateInvoice}
                    variant="contained"
                    sx={{ px: 7, py: 2, borderRadius: "8px" }}
                  >
                    Request Payment
                  </Button>
                </Box>
              </Box>
            </>
          )}
        </>
      )}
    </>
  );
};

export default Payer;
