import React, { useState, useEffect } from "react";
import { usePathname } from "../../hooks/use-pathname";
import { Box, Typography, ListItemButton, Button } from "@mui/material";
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";
import RouterLink from "../../hooks/router-link";
import navConfig from "../config-Navigation-Team";
import bgImg from "../../assets/img/Rectangle 152158.jpg";
import logo from "../../assets/logo/single.svg";
import Faqs from "../../pages/dashboard/Faqs";
import { getPendingOrders } from "../../api";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../store/userSlice";
import { useNavigate } from "react-router-dom";

const Nav = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [count, setCount] = useState(0);
  const handleGetPendingOrders = async () => {
    setIsLoading(true);
    await getPendingOrders()
      .then((res) => {
        setIsLoading(false)
        if (res?.data?.status) {
          setCount(res?.data?.data?.order_count);
        }

      })
      .catch((err) => {
        setIsLoading(false)

      });
  };

  useEffect(() => {
    handleGetPendingOrders();
  }, []);

  const handleLogout = () =>  {
dispatch(logoutUser)
navigate("/login/team")
  }
  return (
    <>
      <Box
        sx={{
          backgroundImage: `url('${bgImg}')`,
          height: "100vh",
          width: "242px",
          backgroundSize: "cover",
          backgroundPosition: "bottom",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box sx={{ mt: 6 }}>
          <img src={logo} />
        </Box>
        <Stack component="nav" spacing={3} sx={{ px: 2, mt: 7 }}>
          {navConfig.map((item) => (
            <NavItem
              key={item.title}
              item={item}
              count={count}
              isLoading={isLoading}
            />
          ))}
          <Box sx={{position:'fixed', bottom:100, }}>
        <Button onClick={handleLogout} fullWidth  sx={{bgcolor:'#FFE8E5', color:'#A71200', py:2, px:8, '&:hover':{
          bgcolor:'#FFE8E5b7'
        }}}>Logout</Button>
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export default Nav;

// ----------------------------------------------------------------------

function NavItem({ item, count, isLoading }) {
  const pathname = usePathname();

  const active = item.path === pathname;

  return (
    <ListItemButton
      component={RouterLink}
      href={item.path}
      sx={{
        minHeight: 44,
        borderRadius: 0.75,
        typography: "body2",
        color: "#8F8F8F",
        textTransform: "capitalize",
        fontWeight: "fontWeightMedium",
        ...(active && {
          color: "#F489FD",
          fontWeight: "fontWeightSemiBold",
          bgcolor: "",
          "&:hover": {
            bgcolor: "",
          },
        }),
      }}
    >
      <Box component="span" sx={{ width: 24, height: 24, mr: 2 }}>
        {active ? item.icon2 : item.icon}
      </Box>

      <Box
        component="span"
        sx={{ display: "flex", alignItems: "center", columnGap: 1 }}
      >
        {item.title}{" "}
        {item?.count && (
          <Box
       
          >
            {isLoading ? (
              <>
                <svg width={0} height={0}>
        <defs>
          <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="#e01cd5" />
            <stop offset="100%" stopColor="#1CB5E0" />
          </linearGradient>
        </defs>
      </svg>
      <CircularProgress sx={{ 'svg circle': { stroke: 'url(#my_gradient)' } }}  size={20}/>
      </>
            ) : (
              <Box      sx={{
              bgcolor: "#75007E",
              color: "#fff",
              minWidth: "16px",
              minHeight: "16px",
              display: "grid",
              placeItems: "center",
              fontSize: "10px",
              borderRadius: "50%",
            }}>
              {count}
              </Box>
            )}
          </Box>
        )}
      </Box>
    </ListItemButton>
  );
}

NavItem.propTypes = {
  item: PropTypes.object,
};
