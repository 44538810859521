import React, { useState, useEffect } from "react";
import AuthLayout from "./AuthLayout";
import { Box, Typography, Button, Skeleton } from "@mui/material";
import CustomOtp from "../../components/Custom/CustomOtp";
import { onboardingDetails, resetPassword } from "../../api";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUser } from "../../store/userSlice";
import Loader from "../../components/common/Loader";
import { useSnackbar } from "notistack";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const token = new URLSearchParams(window.location.search).get("token");

  const vendor_id = `${id}/?token=${token}`;

  const handlePasswordChange = (newValue) => {
    setPassword(newValue);
  };

  const handleConfirmPasswordChange = (newValue) => {
    setConfirmPassword(newValue);
    if (newValue !== password) {
      setError(true);
    } else {
      setError(false);
    }
  };

  const handleAlert = (message, variant) => {
    enqueueSnackbar(message, { variant });
  };

  const handleResetPassword = async () => {
    setLoading2(true);
    await resetPassword(vendor_id, password, confirmPassword)
      .then((res) => {
        setLoading2(false);
        if (res?.data?.status) {
          navigate(`/reset/success`);
        }

      })
      .catch((err) => {
        setLoading2(false);
       
        handleAlert(`${err.message}`, "error");
      });
  };

  //   useEffect(()=>{
  // const getOnboardingDetails = async () =>{
  //   setLoading(true)
  //   await onboardingDetails(token).then((res)=>{
  //     setLoading(false)
  //     setData(res?.data?.data)


  //   }).catch((err)=>{
  //     setLoading(false)
  //     // navigate("/login")

  //     handleAlert("Vendor already onboard, Login instead", "error")
  //   })
  // }

  // getOnboardingDetails()
  //   },[token])
  return (
    <>
      <AuthLayout>
        {loading2 && <Loader />}
        <Box sx={{ width: {lg:"70%", md:'70%', sm:'100%', xs:'100%'} }}>
          {loading ? (
            <Box sx={{ display: "flex", alignItems: "center", columnGap: 2 }}>
              <Typography variant="h2" sx={{   color:'#fff'}}>Hello </Typography>
              <Skeleton
                width={130}
                height={40}
                sx={{ bgcolor: "grey.900", borderRadius: 0 }}
              />
            </Box>
          ) : (
            <Typography variant="h2">Hello {data?.name},</Typography>
          )}

          <Typography
            variant="body1"
            sx={{
              lineHeight: "19px",
             mt: {lg:3, md:3, sm:2, xs:1},
              display: loading ? "flex" : "block",
              alignItems: "center",
              fontSize:{lg:'14px', md:'14px', sm:'12px', xs:'12px'},
              color:'#fff'
            }}
          >
            Kindly setup a new six digits password for your profile-
            {loading ? (
              <Skeleton
                width={130}
                height={30}
                sx={{ bgcolor: "grey.900", borderRadius: 0 }}
              />
            ) : (
              <span style={{ color: "#F489FD" }}> {data?.spot_name}</span>
            )}
          </Typography>

          <Box sx={{ mt: 5 }}>
            <Typography variant="body1" sx={{ mt: 3,        fontSize:{lg:'14px', md:'14px', sm:'12px', xs:'12px'} }}>
              Create six (6) digit Password
            </Typography>
            <Box sx={{ mt: 1 }}>
              <CustomOtp
                type
                otp={password}
                handleChange={handlePasswordChange}
              />
            </Box>
            {error ? (
              <Typography variant="body1" sx={{ mt: 3, color: "#EA8072",        fontSize:{lg:'14px', md:'14px', sm:'12px', xs:'12px'} ,    color:'#fff'}}>
                Confirm Password (password must match)
              </Typography>
            ) : (
              <Typography variant="body1" sx={{ mt: 3 ,        fontSize:{lg:'14px', md:'14px', sm:'12px', xs:'12px',    color:'#fff'}}}>
                Confirm six (6) digit Password
              </Typography>
            )}
            <Box sx={{ mt: 1 }}>
              <CustomOtp
                type
                error={error}
                otp={confirmPassword}
                handleChange={handleConfirmPasswordChange}
              />
            </Box>
          </Box>
          <Box sx={{ mt: 5 }}>
            <Button
              disabled={!password || !confirmPassword || error}
              onClick={handleResetPassword}
              variant="contained"
              sx={{
                height: "63px",
                 width: {lg:"300px", md:'300px', sm:'100%', xs:'100%'},
                borderRadius: "10px",
                "&:disabled": { background: "#5b5b5b" },
              }}
            >
              {" "}
              Reset Password{" "}
            </Button>
          </Box>
        </Box>
      </AuthLayout>
    </>
  );
};

export default ResetPassword;
