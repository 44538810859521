import { Box, useMediaQuery, useTheme } from "@mui/material";
import Nav from "./Nav";
import Main from "./Main";
import PropTypes from 'prop-types';
import Drawers from "./Drawers";



export function DashboardLayout({children}){
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    return (
<>
<Box    sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: {  lg: 'row', md:'row', sm:'column', xs:'column' },
          bgcolor:'#F4F4F4',

        }}>
            {
                isMobile ? (
                    <Drawers/>
                ):(

                    <Nav/>
                )
            }
            <Main>{children}</Main>
        </Box>
</>
    )
}

DashboardLayout.propTypes = {
    children: PropTypes.node,
  };