
import { Navigate, useLocation } from 'react-router-dom';
// import { useAuthContext } from '../hooks/useAuthContext';

const ProtectedRoute = ({ children }) => {
  const user = JSON.parse(window.localStorage.getItem("vendorInfo"));
const location = useLocation()

  if (user === null || user === undefined) {
    return <Navigate to="/login" state={{ redirectTo: location.pathname }} />;
  }

  return children;
};

export default ProtectedRoute;