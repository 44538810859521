import { AppBar, Box, Drawer, IconButton, Typography , ListItemButton} from "@mui/material";
import React, {useState, useEffect} from "react";
import { RiMenu2Line } from "react-icons/ri";
import PropTypes from "prop-types";
import { usePathname } from "../hooks/use-pathname";
import bgImg from "../assets/img/Rectangle 152158.jpg";
import Stack from "@mui/material/Stack";
import RouterLink from "../hooks/router-link";
import navConfig from "./config-Navigation";
import logo from "../assets/logo/logo.svg";
import Faqs from "../pages/dashboard/Faqs";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import { getDashboard, getPendingOrders } from "../api";


const Drawers = () => {
   const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  const closeDrawer  = () => {
    setOpen(false);
    // alert(open)
  };

    const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(0);
  const handleGetPendingOrders = async () => {
    setIsLoading(true);
    await getPendingOrders()
      .then((res) => {
        setIsLoading(false)
        if (res?.data?.status) {
      
                         localStorage.setItem("pending_order_count", res?.data?.data?.order_count
)
        }

      })
      .catch((err) => {
        setIsLoading(false)

      });
  };

  useEffect(() => {
    handleGetPendingOrders();
  }, []);

  useEffect(()=>{
  const get_count = localStorage.getItem("pending_order_count")

setCount(get_count)
  },[])

    const [count2, setCount2] = useState(0);
  const handleReservationOrders = async () => {
    setIsLoading(true);
    await getDashboard()
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.status) {
          setCount2(res?.data?.data?.today_reservation);
        }

      })
      .catch((err) => {
        setIsLoading(false);

      });
  };

  useEffect(() => {
    handleReservationOrders();
  }, []);

  return (
    <>
      <AppBar sx={{ bgcolor: "#f5f5f5", boxShadow: "none" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mt: 1,
          }}
        >
          <IconButton onClick={()=>setOpen(true)}>
            <RiMenu2Line style={{ color: "#151515", fontSize: "20px" }} />
          </IconButton>
          <Typography
            sx={{
              bgcolor: "#FFF2E5",
              border: "1px solid #FFB872",
              px: 2,
              fontSize: "10px",
              mr: 1,
              borderRadius: "20px",
              color: "#C56000",
            }}
          >
            {count2} Reservation Today
          </Typography>
        </Box>
      </AppBar>

       <Drawer open={open} onClose={toggleDrawer(false)} anchor="left">
<Box sx={{width:{xs:'70vw', sm:'50vw'},     backgroundImage: `url('${bgImg}')`,
          height: "100vh",     backgroundSize: "cover",
          backgroundPosition: "bottom",p:3, boxSizing:'border-box'}}>
    <Box sx={{ mt: 0 }}>
          <img src={logo} width={100} />
        </Box>
            <Stack component="nav" spacing={3} sx={{  mt: 7 }}>
          {navConfig.map((item) => (
            <NavItem
          toggleDrawer={closeDrawer}
              key={item.title}
              item={item}
              count={count}
              isLoading={isLoading}
            />
          ))}
          <Box>
            <Faqs />
          </Box>
        </Stack>
</Box>
       </Drawer>
    </>
  );
};

export default Drawers;

function NavItem({ item, count, isLoading, toggleDrawer }) {
  const pathname = usePathname();

  const active = item.path === pathname;

  const handleToggleDrawer = () =>{
    toggleDrawer()
    
  }
  return (
    <ListItemButton
      component={RouterLink}
      href={item.path}
      onClick={handleToggleDrawer}
      sx={{
        minHeight: 44,
        borderRadius: 0.75,
        typography: "body2",
        color: "#8F8F8F",
        textTransform: "capitalize",
        fontWeight: "fontWeightMedium",
        ...(active && {
          color: "#F489FD",
          fontWeight: "fontWeightSemiBold",
          bgcolor: "",
          "&:hover": {
            bgcolor: "",
          },
        }),
      }}
    >
      <Box component="span" sx={{ width: 24, height: 24, mr: 2 }}>
        {active ? item.icon2 : item.icon}
      </Box>

      <Box
        component="span"
        sx={{ display: "flex", alignItems: "center", columnGap: 1 }}
      >
        {item.title}{" "}
        {item?.count && (
          <Box
       
          >
            {isLoading ? (
              <>
                <svg width={0} height={0}>
        <defs>
          <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="#e01cd5" />
            <stop offset="100%" stopColor="#1CB5E0" />
          </linearGradient>
        </defs>
      </svg>
      <CircularProgress sx={{ 'svg circle': { stroke: 'url(#my_gradient)' } }}  size={20}/>
      </>
            ) : (
              <Box      sx={{
              bgcolor: "#75007E",
              color: "#fff",
              minWidth: "16px",
              minHeight: "16px",
              display: "grid",
              placeItems: "center",
              fontSize: "10px",
              borderRadius: "50%",
            }}>
              {count}
              </Box>
            )}
          </Box>
        )}
      </Box>
    </ListItemButton>
  );
}

NavItem.propTypes = {
  item: PropTypes.object,
};
