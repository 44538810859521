import { AppBar, Box, Drawer, IconButton, Typography , ListItemButton} from "@mui/material";
import React, {useState, useEffect} from "react";
import logo from "../../assets/logo/logo.svg";
import { RiMenu2Line } from "react-icons/ri";
import PropTypes from "prop-types";
import { usePathname } from "../../hooks/use-pathname";
import bgImg from "../../assets/img/Rectangle 152158.jpg";
import Stack from "@mui/material/Stack";
import RouterLink from "../../hooks/router-link";
import navConfig from "../config-Navigation-Team";
import Faqs from "../../pages/dashboard/Faqs";
import { getTeamDashboard } from "../../api";

const Drawers = () => {
     const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  const closeDrawer  = () => {
    setOpen(false);
    // alert(open)
  };

   const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(0);
  const handleGetPendingOrders = async () => {
    setIsLoading(true);
    await getTeamDashboard()
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.status) {
          setCount(res?.data?.data?.today_reservation_count);
        }

      })
      .catch((err) => {
        setIsLoading(false);

      });
  };

  useEffect(() => {
    handleGetPendingOrders();
  }, []);
  return (
    <>
         <AppBar sx={{ bgcolor: "#f5f5f5", boxShadow: "none" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mt: 1,
          }}
        >
          <IconButton onClick={()=>setOpen(true)}>
            <RiMenu2Line style={{ color: "#151515", fontSize: "20px" }} />
          </IconButton>
          <Typography
            sx={{
              bgcolor: "#FFF2E5",
              border: "1px solid #FFB872",
              px: 2,
              fontSize: "10px",
              mr: 1,
              borderRadius: "20px",
              color: "#C56000",
            }}
          >
            {count} Reservation Today
          </Typography>
        </Box>
      </AppBar>

      <Drawer open={open} onClose={toggleDrawer(false)} anchor="left">
<Box sx={{width:{xs:'70vw', sm:'50vw'},     backgroundImage: `url('${bgImg}')`,
          height: "100vh",     backgroundSize: "cover",
          backgroundPosition: "bottom",p:3, boxSizing:'border-box'}}>
    <Box sx={{ mt: 0 }}>
          <img src={logo} width={100} />
        </Box>
            <Stack component="nav" spacing={3} sx={{  mt: 7 }}>
          {navConfig.map((item) => (
            <NavItem
          toggleDrawer={closeDrawer}
              key={item.title}
              item={item}
            
            />
          ))}
          <Box>
            {/* <Faqs /> */}
          </Box>
        </Stack>
</Box>
       </Drawer>
       </>
  )
}

export default Drawers;

function NavItem({ item, toggleDrawer }) {
  const pathname = usePathname();

  const active = item.path === pathname;

  const handleToggleDrawer = () =>{
    toggleDrawer()
    
  }
  return (
    <ListItemButton
      component={RouterLink}
      href={item.path}
      onClick={handleToggleDrawer}
      sx={{
        minHeight: 44,
        borderRadius: 0.75,
        typography: "body2",
        color: "#8F8F8F",
        textTransform: "capitalize",
        fontWeight: "fontWeightMedium",
        ...(active && {
          color: "#F489FD",
          fontWeight: "fontWeightSemiBold",
          bgcolor: "",
          "&:hover": {
            bgcolor: "",
          },
        }),
      }}
    >
      <Box component="span" sx={{ width: 24, height: 24, mr: 2 }}>
        {active ? item.icon2 : item.icon}
      </Box>

      <Box
        component="span"
        sx={{ display: "flex", alignItems: "center", columnGap: 1 }}
      >
        {item.title}{" "}
       
      </Box>
    </ListItemButton>
  );
}

NavItem.propTypes = {
  item: PropTypes.object,
};