import React, { useEffect, useState } from "react";
import { getInvoiceInfo } from "../../../api";
import {
  Avatar,
  Box,
  InputLabel,
  LinearProgress,
  Skeleton,
  Typography,
} from "@mui/material";
import Pusher from 'pusher-js';

import failedIcon from '../../../assets/icons/failed.svg'
import successIcon from '../../../assets/icons/success.svg'


const PaymentStatus = ({ id, idShow, status }) => {
    const [paymentStatus, setPaymentStatus] = useState('');
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState(null);


  const handleGetInvoiceDetails = async () => {
    setIsLoading(true)
    await getInvoiceInfo(id)
      .then((res) => {

        setIsLoading(false)
        setData(res?.data?.data);
      })
      .catch((err) => {
        setIsLoading(false)

      });
  };

  useEffect(() => {
    handleGetInvoiceDetails();
  }, []);

  useEffect(() => {
    // Configure Pusher client
    const pusher = new Pusher('a2814cd83306f1189e9a', {
      cluster: 'mt1',
    });

    // Subscribe to the 'payments' channel
    const channel = pusher.subscribe(`vendor-${2}`);

    // Bind to the 'update' event
    channel.bind('paid_invoice', function (eventData) {

      // const invoices = updateInvoice(data, eventData?.invoice_id, eventData?.status)

if(eventData?.invoice_id === id){
setData({...data, status:eventData?.status})
}    });

    // Clean up the subscription
    return () => {
      channel.unbind_all();
      channel.unsubscribe();
    };
  }, [status]);


//   const updateInvoice = (invoices,id, status ) =>{

// return  invoices?.map((invoice) =>{
// if(   invoice?.invoice_id === id){
//   invoice.status = status
//   return invoice
// }
// return invoice
// } )

// }
  return (
    <>
      <Box>

{
  idShow && (
            <Box sx={{mt:4}}>
          {
            isLoading ? (
              <Skeleton animation="wave" sx={{width:'20%', borderRadius:0}} variant="text"/>
            ):(
              <Typography sx={{color:'#151515', fontWeight:600, }}>{data?.invoice_id
}</Typography>
            )
          }
        </Box>
  )
}
     {
      isLoading && (
           <Box
          align="center"
          sx={{
            mt: 2,
            bgcolor: "#FCEDFE",
            p: 5,
            boxSizing: "border-box",
            borderRadius: "12px",
          }}
        >
      
          <Box sx={{ my: 4, bgcolor: "#fff", p: 1, width: "70%" }}>
            <LinearProgress />
          </Box>
        
        </Box>
      )
     }

     {
      (data && data?.status === "pending") &&  (
           <Box
          align="center"
          sx={{
            mt: 2,
            bgcolor: "#FCEDFE",
            p: 5,
            boxSizing: "border-box",
            borderRadius: "12px",
          }}
        >
          <Typography color="primary" sx={{ textAlign: "center" }}>
            A notification has been sent to your guest’s app. Please advise the
            guest to login to the VibezsUp app to complete payment
          </Typography>
          <Box sx={{ my: 4, bgcolor: "#fff", p: 1, width: "70%" }}>
            <LinearProgress />
          </Box>
          <Typography sx={{ color: "#151515" }}>
            Confirmation expires{" "}
            <span style={{ color: "#A71200" }}> 09:39</span>
          </Typography>
        </Box>
      )
     }
     {
      (data && data?.status === "failed") &&  (
           <Box
          align="center"
          sx={{
            mt: 2,
            bgcolor: "#FCEDFE",
            p: 5,
            boxSizing: "border-box",
            borderRadius: "12px",
          }}
        >
  
      <img src={failedIcon} />
          <Typography sx={{ color: "#151515" }}>
       Payment Confirmation failed
          </Typography>
        </Box>
      )
     }
     {
      (data && data?.status === "declined") &&  (
           <Box
          align="center"
          sx={{
            mt: 2,
            bgcolor: "#FCEDFE",
            p: 5,
            boxSizing: "border-box",
            borderRadius: "12px",
          }}
        >
  
      <img src={failedIcon} />
          <Typography sx={{ color: "#151515", textAlign:'center' }}>
      Oops! Your Guest declined this Invoice Payment Request
          </Typography>
        </Box>
      )
     }
     {
      (data && data?.status === "success") &&  (
           <Box
          align="center"
          sx={{
            mt: 2,
            bgcolor: "#FCEDFE",
            p: 5,
            boxSizing: "border-box",
            borderRadius: "12px",
          }}
        >
  
      <img src={successIcon} />
          <Typography sx={{ color: "#151515", textAlign:'center' }}>
     Payment Successfully Confirmed
          </Typography>
        </Box>
      )
     }
      {
        isLoading ?  (
          <>
            <Box
          sx={{
            mt: 4,
            bgcolor: "#FCEDFE",
            p: 5,
            boxSizing: "border-box",
            borderRadius: "12px",
            mt: 4,
          }}
        >
          <Typography color="primary">Paying Guest</Typography>
          <Box
            sx={{
              mt: 3,
              bgcolor: "#151515",
              p: 3,
              boxSizing: "border-box",
              width: "60%",
              borderRadius: "9.7px",
              display: "flex",
              alignItems: "center",
              columnGap: 3,
            }}
          >
       <Skeleton variant="rounded" sx={{width:'40px', height:'40px', bgcolor:'#ddd'}}/>
            <Box>
<Skeleton variant="text"  sx={{bgcolor:'#ddd', width:'120px'}}/>
<Skeleton variant="text"  sx={{bgcolor:'#ddd', width:'70px'}}/>

            </Box>
          </Box>

          <Box sx={{ mt: 4 }}>
            <Typography color="primary">Reservation Order</Typography>
            <Box sx={{ mt: 2 }}>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 1,
                  }}
                >
                  <Typography sx={{ color: "#151515", fontWeight: 500 }}>
            <Skeleton variant="text" animation="wave"  sx={{ width:'100px'}}/>
                  </Typography>
                  <Typography sx={{ color: "#151515", fontWeight: 600 }}>
              <Skeleton variant="text"  animation="wave" sx={{ width:'70px'}}/>
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 1,
                  }}
                >
                  <Typography sx={{ color: "#151515", fontWeight: 500 }}>
            <Skeleton variant="text" animation="wave"  sx={{width:'100px'}}/>
                  </Typography>
                  <Typography  sx={{ color: "#151515", fontWeight: 600 }}>
              <Skeleton variant="text" animation="wave"  sx={{width:'70px'}}/>
                  </Typography>
                </Box>
      
            </Box>

            <Box sx={{ borderTop: "1px solid #75007E", mt: 2, py: 3 }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                  <Typography sx={{ color: "#151515", fontWeight: 500 }}>
            <Skeleton variant="text" animation="wave"  sx={{width:'100px'}}/>
                  </Typography>
                  <Typography  sx={{ color: "#151515", fontWeight: 600 }}>
              <Skeleton variant="text" animation="wave"  sx={{width:'70px'}}/>
                  </Typography>
              </Box>
        
            </Box>
                 <Box sx={{ borderTop: "1px solid #75007E", mt: 2, py: 3 }}>
                  <Box sx={{display: "flex",justifyContent: "space-between",alignItems: "center"}}>
                     <Typography sx={{ color: "#151515", fontWeight: 500 }}>
            <Skeleton variant="text" animation="wave"  sx={{width:'100px'}}/>
                  </Typography>
                  <Typography  sx={{ color: "#151515", fontWeight: 600 }}>
              <Skeleton variant="text" animation="wave"  sx={{width:'70px'}}/>
                  </Typography>
                  </Box>
                 </Box>
          </Box>
        </Box>
          </>
        ):(
          <>
            <Box
          sx={{
            mt: 4,
            bgcolor: "#FCEDFE",
            p: 5,
            boxSizing: "border-box",
            borderRadius: "12px",
            mt: 4,
          }}
        >
          <Typography color="primary">Paying Guest</Typography>
          <Box
            sx={{
              mt: 3,
              bgcolor: "#151515",
              p: 3,
              boxSizing: "border-box",
              width: "60%",
              borderRadius: "9.7px",
              display: "flex",
              alignItems: "center",
              columnGap: 3,
            }}
          >
            <Avatar  src={data?.paying_guest_avatar} variant="rounded" sx={{ border: "1.5px solid #75007E" }} />
            <Box>
              <InputLabel sx={{ fontSize: "15px" }}>{data?.paying_guest}</InputLabel>
              <InputLabel>@{data?.user_name}</InputLabel>
            </Box>
          </Box>

          <Box sx={{ mt: 4 }}>
            <Typography color="primary">Reservation Order</Typography>
            <Box sx={{ mt: 2 }}>
              {data?.menu_detail?.map((item, index) => (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 1,
                  }}
                >
                  <Typography sx={{ color: "#151515", fontWeight: 500 }}>
                    {item?.menu_item}
                  </Typography>
                  <Typography sx={{ color: "#151515", fontWeight: 600 }}>
                    N{item?.total.toLocaleString()}
                  </Typography>
                </Box>
              ))}
            </Box>

            <Box sx={{ borderTop: "1px solid #75007E", mt: 2, py: 3 }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ color: "#151515", fontWeight: 500 }}>
                  Total
                </Typography>
                <Typography sx={{ color: "#151515", fontWeight: 500 }}>
                  N{parseFloat(data?.menu_total)?.toLocaleString()}
                </Typography>
              </Box>
            {
              data?.VAT && (
                  <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  mt: 1,
                }}
              >
                <Typography sx={{ color: "#151515", fontWeight: 500 }}>
                  VAT (7.5%)
                </Typography>
                <Typography sx={{ color: "#151515", fontWeight: 500 }}>
                  N{parseFloat(data?.VAT)?.toLocaleString()}
                </Typography>
              </Box>
              )
            }
             {
              data?.consumption_tax && (
                 <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  mt:1
                }}
              >
                <Typography sx={{ color: "#151515", fontWeight: 500 }}>
               Consumption Tax (5%)
                </Typography>
                <Typography sx={{ color: "#151515", fontWeight: 500 }}>
                  N{parseFloat(data?.consumption_tax)?.toLocaleString()}
                </Typography>
              </Box>
              )
             }
            </Box>
                 <Box sx={{ borderTop: "1px solid #75007E", mt: 2, py: 3 }}>
                  <Box sx={{display: "flex",justifyContent: "space-between",alignItems: "center"}}>
                    <Typography  sx={{ color: "#007E23", fontWeight: 500 }}>Total Chargeable</Typography>
                    <Typography  sx={{ color: "#007E23", fontWeight: 500 }}>N{parseFloat(data?.
total_chargable)?.toLocaleString()}</Typography>
                  </Box>
                 </Box>
          </Box>
        </Box>

        {
          (data && data?.status === "success" && (
              <Box
          sx={{
            mt: 4,
            bgcolor: "#FCEDFE",
            p: 5,
            boxSizing: "border-box",
            borderRadius: "12px",
            mt: 4,
          }}
        >
       
      

          <Box sx={{ mt: 0 }}>
            <Typography color="primary">Settlement Details</Typography>
            <Box sx={{ mt: 2 }}>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 1,
                  }}
                >
                  <Typography sx={{ color: "#151515", fontWeight: 500 }}>
                  Menu Total
                  </Typography>
                  <Typography sx={{ color: "#151515", fontWeight: 600 }}>
                    N{parseFloat(data?.menu_total
)?.toLocaleString()}
                  </Typography>
                </Box>
   
            </Box>

            <Box sx={{ borderTop: "1px solid #75007E", mt: 2, py: 3 }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ color: "#A71200", fontWeight: 500 }}>
                 Service Charge (0.3% capped @ N2,000)
                </Typography>
                <Typography sx={{ color: "#A71200", fontWeight: 500 }}>
                  -N{parseFloat(data?.
capped_at)?.toLocaleString()}
                </Typography>
              </Box>
            {
              data?.VAT && (
                  <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  mt: 1,
                }}
              >
                <Typography sx={{ color: "#151515", fontWeight: 500 }}>
                  VAT (7.5%)
                </Typography>
                <Typography sx={{ color: "#151515", fontWeight: 500 }}>
                  N{parseFloat(data?.VAT)?.toLocaleString()}
                </Typography>
              </Box>
              )
            }
             {
              data?.consumption_tax && (
                 <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  mt:1
                }}
              >
                <Typography sx={{ color: "#151515", fontWeight: 500 }}>
               Consumption Tax (5%)
                </Typography>
                <Typography sx={{ color: "#151515", fontWeight: 500 }}>
                  N{parseFloat(data?.consumption_tax)?.toLocaleString()}
                </Typography>
              </Box>
              )
             }
            </Box>
                 <Box sx={{ borderTop: "1px solid #75007E", mt: 2, py: 3 }}>
                  <Box sx={{display: "flex",justifyContent: "space-between",alignItems: "center"}}>
                    <Typography  sx={{ color: "#007E23", fontWeight: 500 }}>Total Receivable</Typography>
                    <Typography  sx={{ color: "#007E23", fontWeight: 500 }}>N{parseFloat(data?.

total_receiveable
)?.toLocaleString()}</Typography>
                  </Box>
                 </Box>
          </Box>
        </Box>
          ))
        }
          </>
        )
      }
      </Box>
    </>
  );
};

export default PaymentStatus;
