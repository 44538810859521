import { createTheme } from "@mui/material";

const Theme = createTheme({
  palette: {
    primary: {
      main: "#75007E",
      error:'#A71200',
      success:'#007E23',
      warning:'#C56000'
    },
    background:{
      gray:'#DEDEDE'
    },
    text:{
      gray:'linear-gradient(0deg, #8F8F8F, #8F8F8F), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))'
    }
  },

  typography: {
    fontFamily: "outfit",
    color: "#fff",
    h1: {
      fontSize: "32px",
      color: "#121417",
      fontWeight: 900,
      color: "#fff",
    },
    h2: {
      fontSize: "24px",
      color: "#fff",
      fontWeight: 700,
      fontFamily: "butler",
    },
    h3: {
      fontSize: "20px",
      color: "#151515",
      fontWeight: 700,
      fontFamily: "outfit",
    },
    h6: {
      fontSize: "12px",
      color: "#151515",
      fontWeight: 700,
      fontFamily: "outfit",
    },
    body1: {
      fontSize: "12px",
  
      fontWeight:300,
      fontFamily: "outfit",
      lineHeight: "20px",
    },
    body2: {
      fontSize: "13px",
      color: "#121417",
      fontWeight: 400,
      fontFamily: "outfit",
    },
    subtitle1: {
      fontSize: "13px",
      color: "#151515",
      fontWeight: 500,
      fontFamily: "outfit",
    },
    subtitle2: {
      fontSize: "12px",
      color: "#8f8f8f",
      fontWeight: 500,
      fontFamily: "outfit",
    },
    button: {
      textTransform: "initial",
      fontFamily: "outfit",
      "disabled": {
    "background": "#5b5b5b"
  }
    },
  },
});

export default Theme;
